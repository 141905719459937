import React from "react";
import { Typography, WidthContainer } from "../../../../@ui";
import ObjectivesCard from "./ObjectivesCard";
import { Grid } from "@mantine/core";
import { ObjectivesData } from "../../../../utils/objectivesData";
const Objectives = () => {
  return (
    <WidthContainer>
      <Typography variant="heading_42" align="center" marginBottom={40}>
        Objectives
      </Typography>
      <Grid justify="space-between">
        {ObjectivesData.map((item) => (
          <ObjectivesCard
            image={item.image}
            title={item.title}
            description={item.description}
            key={item.title}
          />
        ))}
      </Grid>
    </WidthContainer>
  );
};

export default Objectives;
