import React from "react";
import HeroBanner from "./components/hero-banner/HeroBanner";
import Objectives from "./components/objectives/Objectives";
import Features from "./components/features/Features";
import VendorFeatures from "./components/vendor-features/VendorFeatures";
import Conclusion from "./components/conclusion/Conclusion";

const Home = () => {
  return (
    <div>
      <HeroBanner />
      <Objectives />
      <Features />
      <VendorFeatures />
      <Conclusion />
    </div>
  );
};

export default Home;
