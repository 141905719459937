import React from "react";
import { Typography, WidthContainer } from "../../../../@ui";

const Conclusion = () => {
  return (
    <WidthContainer>
      <Typography variant="heading_42" align="center" marginBottom={40}>
        Conclusion
      </Typography>
      <Typography variant="description_16" marginBottom={20}>
        The Order List Application aims to provide a convenient and efficient
        solution for both users and vendors. By allowing users to create grocery
        lists, track order status, and pick up groceries at their convenience,
        the application enhances the overall shopping experience. Vendors
        benefit from streamlined order management and preparation processes,
        ensuring efficient service delivery.
      </Typography>
    </WidthContainer>
  );
};

export default Conclusion;
