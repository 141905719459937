import { Box, Button, Grid, TextInput, Textarea } from "@mantine/core";
import React, { useState } from "react";
import classes from "./index.module.scss";
import { useForm } from "@mantine/form";
import { IMAGES } from "../../assets/images";
import { notifications, showNotification } from "@mantine/notifications";
import { Typography } from "../../@ui";
import { client } from "../../service/axios.sevice";

const ContactUs = () => {
  const [loading, setLoading] = useState(false);
  const form = useForm({
    initialValues: {
      email: "",
      name: "",
      message: "",
    },
    validate: {
      name: (value) => (value.length < 2 ? "Required" : null),
      email: (value) => (/^\S+@\S+$/.test(value) ? null : "Invalid email"),
      message: (value) =>
        value.length < 2 ? "Message must have at least 2 letters" : null,
    },
  });
  const handleSubmit = async (data: any) => {
    console.log("data", data);

    try {
      setLoading(true);
      const res = await client({ url: "/web/contact", method: "POST", data });
      if (res.data?.status === "success") {
        notifications.show({
          title: res.data.title,
          message: res.data.message,
          color: "green",
        });

        form.reset();
      }
    } catch (error: any) {
      showNotification({
        title: error.response.data.title,
        message: error.response.data.message,
        color: "red",
        position: "top-right",
      });
    } finally {
      setLoading(false);
    }
  };
  return (
    <Box className={classes.root}>
      <Typography
        variant="heading_42"
        tag="h2"
        align="center"
        marginBottom={30}
      >
        Contact Us
      </Typography>
      <Grid align="center" justify="center">
        <Grid.Col span={{ base: 12, xs: 6, md: 6, lg: 6 }}>
          <Box>
            <img
              src={IMAGES.contact_us}
              alt="contact_us"
              className={classes.image}
            />
          </Box>
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, md: 6, lg: 6 }}>
          <form action="" onSubmit={form.onSubmit(handleSubmit)}>
            <Box className={classes.inputContainer}>
              <TextInput
                size="md"
                label="Name"
                placeholder="Enter Your Name"
                {...form.getInputProps("name")}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <TextInput
                size="md"
                label="Email"
                placeholder="Enter Your Email"
                {...form.getInputProps("email")}
              />
            </Box>
            <Box className={classes.inputContainer}>
              <Textarea
                autosize
                label={"Message"}
                placeholder="Enter Your Message"
                minRows={4}
                maxRows={5}
                {...form.getInputProps("message")}
                radius="xs"
              />
            </Box>
            <Button
              type="submit"
              fullWidth
              className={classes.button}
              loading={loading}
            >
              <b>Submit</b>
            </Button>
          </form>
        </Grid.Col>
      </Grid>
    </Box>
  );
};

export default ContactUs;
