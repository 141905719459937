import React from "react";
import { Typography, WidthContainer } from "../../../../@ui";
import classes from "./index.module.scss";
import { Grid } from "@mantine/core";
import FeaturesCard from "./FeaturesCard";
import { UserFeaturesData } from "../../../../utils/userFeaturesData";

const Features = () => {
  return (
    <div className={classes.root}>
      <WidthContainer>
        <Typography variant="heading_42" align="center" marginBottom={40}>
          User Features
        </Typography>
        {UserFeaturesData.map((item, index) => (
          <Grid key={item.image + index} className={classes.gridContainer}>
            <Grid.Col
              span={{ base: 12, sm: 6, md: 6, lg: 7 }}
              order={item.orderOne}
              className={classes.orderOne}
            >
              <Grid gutter="xl">
                {item.listData.map((item, index) => (
                  <Grid.Col
                    span={{ base: 12, xs: 6, sm: 12 }}
                    key={item.title + index}
                  >
                    <FeaturesCard title={item.title} list={item.list} />
                  </Grid.Col>
                ))}
              </Grid>
            </Grid.Col>
            <Grid.Col
              span={{ base: 12, sm: 6, md: 6, lg: 5 }}
              order={item.orderTwo}
              className={classes.orderTwo}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  src={item.image}
                  alt=""
                  className={classes.featuresImages}
                />
              </div>
            </Grid.Col>
          </Grid>
        ))}
      </WidthContainer>
    </div>
  );
};

export default Features;
