import { IMAGES } from "../assets/images";

export const VendorFeaturesData = [
  {
    listData: [
      {
        title: "Order Management",
        list: [
          "Vendors can view and manage incoming grocery orders.",
          "Vendors can update the status of each order (e.g., prepared, ready for pickup).",
        ],
      },
      {
        title: "Preparation and Packing",
        list: [
          "Vendors can prepare and pack the items as per the user’s grocery list.",
          "Vendors can update the final amount based on the actual items packed.",
        ],
      },
      {
        title: "Notification System",
        list: [
          "Vendors can send notifications to users regarding the status of their order.",
          "Vendors can inform users when their order is ready for pickup",
        ],
      },
    ],
    image: IMAGES.features_management,
  },
];
