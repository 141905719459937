import { IMAGES } from "../assets/images";

export const UserFeaturesData = [
  {
    listData: [
      {
        title: "Create Grocery List",
        list: [
          "Users can create and manage their daily grocery items through the app.",
          "Users can add, edit, or delete items from their list.",
          "Users can specify the quantity of each item.",
        ],
      },
      {
        title: "Order Submission",
        list: [
          "Users can submit their grocery list to the vendor with a single click.",
          "Users receive a notification once the order is received by the vendor.",
        ],
      },
    ],
    image: IMAGES.features_list,
    orderOne: 1,
    orderTwo: 2,
  },
  {
    listData: [
      {
        title: "Order Status",
        list: [
          "Users can track the status of their order (e.g., pending, prepared, ready for pickup).",
          "Users receive notifications when their order is ready for pickup.",
        ],
      },
      {
        title: "Final Amount Notification",
        list: [
          "Users receive the final amount of their order once it has been prepared by the vendor.",
          "Users can review the final amount before proceeding to the shop.",
        ],
      },
    ],
    image: IMAGES.features_order_status,
    orderOne: 2,
    orderTwo: 1,
  },

  {
    listData: [
      {
        title: "Flexible Pickup",
        list: [
          "Users can pick up their groceries at their convenience.",
          "Users can view the shop's operating hours and location details.",
        ],
      },
      {
        title: "Secure Payment",
        list: [
          "Users pay the final amount directly to the shopkeeper upon pickup.",
          "The app can optionally support online payment methods.",
        ],
      },
    ],
    image: IMAGES.features_secure_payment,
    orderOne: 1,
    orderTwo: 2,
  },

  // {
  //
  // },
  // {
  //
  // },
  // {
  //
  // },
  // {
  //
  // },
];
