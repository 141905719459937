import { Box } from "@mantine/core";
import React from "react";
import { Typography, WidthContainer } from "../../@ui";
import classes from "./index.module.scss";

const PrivacyPolicy = () => {
  return (
    <Box className={classes.root}>
      <WidthContainer>
        <Typography
          variant="title_80"
          tag="h1"
          align="center"
          marginBottom={12}
        >
          Privacy Policy
        </Typography>
        <Typography variant="subHeading_28" align="center" marginBottom={26}>
          Effective Date: 25.07.2024
        </Typography>
        <Typography variant="subHeading_32" marginBottom={16}>
          Introduction
        </Typography>
        <Typography variant="description_16" marginBottom={16}>
          Welcome to the Order List Application. Your privacy is important to
          us. This Privacy Policy explains how we collect, use, disclose, and
          safeguard your information when you use our App. Please read this
          Privacy Policy carefully. By using the App, you agree to the
          collection and use of information in accordance with this policy.
        </Typography>
        <Typography variant="subHeading_32" marginBottom={16}>
          Information We Collect
        </Typography>
        <Typography variant="description_16">
          We may collect and process the following types of information:
        </Typography>
        <ul>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Personal Information:</b> When you register or use the App, we
            may collect information such as your name, email address, phone
            number, and address.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b> Grocery List Information:</b> Details of the grocery items you
            add to your list, including item names, quantities, and preferences.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b> Transaction Information:</b> Details about payments made for
            your orders.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={26}>
            <b> Usage Data:</b> Information on how you access and use the App,
            including your interactions, device information, and log data.
          </Typography>
        </ul>
        <Typography variant="subHeading_32" marginBottom={8}>
          How We Use Your Information
        </Typography>
        <Typography variant="description_16">
          We use the collected information for the following purposes:
        </Typography>
        <ul>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b> To Provide and Maintain the Service:</b> To manage your orders,
            process payments, and communicate with you about your orders.
          </Typography>

          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b> To Improve the App:</b> To understand how users interact with
            the App and to enhance the user experience.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            {" "}
            <b>To Communicate with You:</b> To send you updates, notifications,
            and other information regarding your orders and the App.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={26}>
            <b> To Ensure Security:</b> To detect and prevent fraud and other
            harmful activities.
          </Typography>
        </ul>
        <Typography variant="subHeading_32" marginBottom={8}>
          Sharing Your Information
        </Typography>
        <Typography variant="description_16">
          We may share your information with:
        </Typography>
        <ul>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Vendors:</b> To prepare and pack your grocery orders.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Service Providers:</b> To perform services on our behalf, such as
            payment processing and data analysis.
          </Typography>

          <Typography variant="description_16" tag="li" marginBottom={26}>
            <b>Legal Authorities:</b> If required by law or to protect our
            rights and property.
          </Typography>
        </ul>
        <Typography variant="subHeading_32" marginBottom={16}>
          Data Security
        </Typography>
        <Typography variant="description_16" marginBottom={26}>
          We use administrative, technical, and physical security measures to
          protect your personal information. While we strive to use commercially
          acceptable means to protect your information, we cannot guarantee its
          absolute security.
        </Typography>
        <Typography variant="subHeading_32" marginBottom={16}>
          Data Retention
        </Typography>
        <Typography variant="description_16" marginBottom={26}>
          We retain your personal information only for as long as is necessary
          for the purposes set out in this Privacy Policy, and to the extent
          necessary to comply with our legal obligations, resolve disputes, and
          enforce our policies.
        </Typography>
        <Typography variant="subHeading_32" marginBottom={8}>
          Your Rights
        </Typography>
        <Typography variant="description_16">
          Depending on your location, you may have the following rights
          regarding your personal information:
        </Typography>
        <ul>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Access:</b> You can request a copy of the personal information we
            hold about you.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Correction:</b> You can request that we correct any inaccuracies
            in your personal information.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Deletion:</b> You can request that we delete your personal
            information.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Objection:</b> You can object to the processing of your personal
            information.
          </Typography>
          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Restriction:</b> You can request that we restrict the processing
            of your personal information.
          </Typography>

          <Typography variant="description_16" tag="li" marginBottom={12}>
            <b>Portability:</b> You can request a copy of your personal
            information in a structured, commonly used, and machine-readable
            format.
          </Typography>
        </ul>
        <Typography variant="description_16" marginBottom={26}>
          To exercise any of these rights, please contact us at
          <b> [orderlist.meerasolution.com]</b>.
        </Typography>
        <Typography variant="subHeading_32" marginBottom={16}>
          Children's Privacy
        </Typography>
        <Typography variant="description_16" marginBottom={16}>
          Our App is not intended for children under the age of 13. We do not
          knowingly collect personal information from children under 13. If we
          become aware that we have collected personal information from a child
          under 13, we will take steps to delete such information.
        </Typography>
        <Typography variant="subHeading_32" marginBottom={16}>
          Changes to This Privacy Policy
        </Typography>
        <Typography variant="description_16" marginBottom={16}>
          We may update our Privacy Policy from time to time. We will notify you
          of any changes by posting the new Privacy Policy on this page. You are
          advised to review this Privacy
        </Typography>
      </WidthContainer>
    </Box>
  );
};

export default PrivacyPolicy;
