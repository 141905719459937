import React, { FC } from "react";
import classes from "./index.module.scss";
import { Typography } from "../../../../@ui";
import { IMAGES } from "../../../../assets/images";

interface IProps {
  title: string;
  list: string[];
}

const FeaturesCard: FC<IProps> = ({ list, title }) => {
  return (
    <div className={classes.boxContainer}>
      <Typography variant="description_20" fontWeight={600} tag="h3">
        {title}
      </Typography>
      <ul className={classes.ul}>
        {list.map((item) => (
          <div key={item} className={classes.flexContainer}>
            <div>
              <img
                src={IMAGES.icon_check}
                alt="icon_check"
                className={classes.checkIcon}
              />
            </div>
            <Typography variant="description_16" tag="li" marginBottom={6}>
              {item}
            </Typography>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default FeaturesCard;
