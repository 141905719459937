import React, { FC } from "react";
import { Typography } from "../../../../@ui";
import classes from "./index.module.scss";
import { Grid } from "@mantine/core";

interface IProps {
  title: string;
  description: string;
  image: string;
}

const ObjectivesCard: FC<IProps> = ({ title, description, image }) => {
  return (
    <Grid.Col
      span={{ base: 12, sm: 5.8, md: 5.8, lg: 5.8 }}
      className={classes.bgContainer}
    >
      <div>
        <img src={image} alt="" className={classes.icon} />
        <Typography
          variant="subHeading_24"
          fontWeight={600}
          tag="h3"
          color="gray"
        >
          {title}
        </Typography>
        <Typography variant="description_16">{description}</Typography>
      </div>
    </Grid.Col>
  );
};

export default ObjectivesCard;
