import { IMAGES } from "../assets/images";

export const ObjectivesData = [
  {
    image: IMAGES.icon_convenience,
    title: "User Convenience",
    description:
      "Allow users to create and manage their daily grocery lists from their mobile devices.",
  },
  {
    image: IMAGES.icon_efficiency,
    title: "Vendor Efficiency",
    description:
      "Enable vendors to receive, prepare, and pack grocery orders efficiently.",
  },
  {
    image: IMAGES.icon_pick,
    title: "Flexible Pickup",
    description:
      "Provide users with the flexibility to pick up their groceries at their convenience.",
  },
  {
    image: IMAGES.icon_secure_payment,
    title: "Secure Payment",
    description: "Facilitate secure payment upon pickup at the shop.",
  },
];
