import logo from "./logo.png";
import logoName from "./logo-name.png";
import google_play from "./google-play-badge.svg";
import footer_banner from "./footer-background.svg";
import hero_banner from "./hero-banner.webp";
import icon_convenience from "./icons/convenience.png";
import icon_efficiency from "./icons/efficiency.png";
import icon_pick from "./icons/pick.png";
import icon_secure_payment from "./icons/secure-payment.png";
import icon_check from "./icons/check.png";
import features_list from "./features/list.png";
import features_order_status from "./features/order-status.png";
import features_secure_payment from "./features/secure-payment.jpg";
import features_management from "./features/management.png";
import contact_us from "./contact-us.png";

export const IMAGES = {
  logo,
  logoName,
  google_play,
  footer_banner,
  hero_banner,
  icon_convenience,
  icon_efficiency,
  icon_pick,
  icon_secure_payment,
  icon_check,
  features_list,
  features_order_status,
  features_secure_payment,
  features_management,
  contact_us,
};
