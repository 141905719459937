import { Box, Grid } from "@mantine/core";
import React from "react";
import { Typography, WidthContainer } from "../../../../@ui";
import classes from "./index.module.scss";
import { IMAGES } from "../../../../assets/images";

const HeroBanner = () => {
  return (
    <div className={classes.root}>
      <WidthContainer>
        <Grid>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 7, lg: 7 }}
            className={classes.orderOne}
          >
            <Typography variant="heading_42" marginBottom={16} tag="h1">
              <span className={classes.titleColor}> Order List:</span>{" "}
              Revolutionizing Your Grocery Shopping Experience
            </Typography>
            <Typography variant="description_16" marginBottom={30} color="gray">
              This document provides an overview of the Android application
              designed to streamline the grocery shopping experience. The
              application allows users to create a list of daily grocery items,
              which are then visible to the vendor. The vendor can prepare and
              pack these items, and send the final amount to the user. The user
              can pick up the groceries at their convenience and pay the
              shopkeeper upon arrival.
            </Typography>
            <Box className={classes.googleContainer}>
              <a
                href="https://play.google.com/store/apps/details?id=com.walletmanagement"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={IMAGES.google_play}
                  alt=""
                  className={classes.googleImg}
                />
              </a>
            </Box>
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 5, lg: 5 }}
            className={classes.orderTwo}
          >
            <div style={{ textAlign: "center" }}>
              <img
                src={IMAGES.hero_banner}
                alt=""
                className={classes.heroImage}
              />
            </div>
          </Grid.Col>
        </Grid>
      </WidthContainer>
    </div>
  );
};

export default HeroBanner;
