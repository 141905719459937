import React from "react";
import classes from "./index.module.scss";
import { Typography, WidthContainer } from "../../../../@ui";
import VendorFeaturesCard from "./VendorFeaturesCard";
import { Grid } from "@mantine/core";
import { VendorFeaturesData } from "../../../../utils/vendorFeaturesData";

const VendorFeatures = () => {
  return (
    <WidthContainer>
      <Typography variant="heading_42" align="center" marginBottom={40}>
        Vendor Features
      </Typography>
      {VendorFeaturesData.map((item, index) => (
        <Grid key={item.image + index} className={classes.gridContainer}>
          <Grid.Col
            span={{ base: 12, sm: 6, md: 6, lg: 6 }}
            className={classes.orderOne}
          >
            <Grid gutter="xl">
              {item.listData.map((item, index) => (
                <Grid.Col
                  span={{ base: 12, xs: 6, sm: 12 }}
                  key={item.title + index}
                >
                  <VendorFeaturesCard title={item.title} list={item.list} />
                </Grid.Col>
              ))}
            </Grid>
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 6, md: 6, lg: 6 }}
            className={classes.orderTwo}
          >
            <div style={{ textAlign: "center" }}>
              <img src={item.image} alt="" className={classes.featuresImages} />
            </div>
          </Grid.Col>
        </Grid>
      ))}
    </WidthContainer>
  );
};

export default VendorFeatures;
