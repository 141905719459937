import { MantineProvider } from "@mantine/core";
import React from "react";
import { RouterProvider } from "react-router-dom";
import { appRouters } from "./router/appRouters";

const App = () => {
  return (
    <MantineProvider>
      <RouterProvider router={appRouters} />
    </MantineProvider>
  );
};

export default App;
