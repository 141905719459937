import { BackgroundImage, Box, Grid } from "@mantine/core";
import React, { memo } from "react";
import { IMAGES } from "../../assets/images";
import classes from "./index.module.scss";
import { Link } from "react-router-dom";
import { Typography, WidthContainer } from "../../@ui";

const TheFooter = () => {
  return (
    <Box>
      <BackgroundImage src={IMAGES.footer_banner} className={classes.root}>
        <WidthContainer>
          <Grid justify="center" align="center">
            <Grid.Col span={{ base: 12, xs: 6, md: 6 }}>
              <Box className={classes.imageContainer}>
                <img
                  src={IMAGES.logoName}
                  alt="logo"
                  className={classes.images}
                />
              </Box>
            </Grid.Col>
            <Grid.Col span={{ base: 12, xs: 6, md: 6 }}>
              <Box className={classes.flexContainer}>
                <Link
                  to={"/privacy-policy"}
                  target="_parent"
                  className={classes.linkPrivacy}
                >
                  Privacy Policy
                </Link>
                <Typography variant="description_20">|</Typography>
                <Link
                  to={"/contact-us"}
                  className={classes.linkPrivacy}
                  target="_parent"
                >
                  Contact Us
                </Link>
              </Box>
            </Grid.Col>
          </Grid>
          <Box className={classes.flexContainer} style={{ marginTop: 40 }}>
            <Typography variant="description_16" align="center">
              Designed & Developed by
            </Typography>
            <Link
              to={"https://meerasolution.com/"}
              target="_blank"
              className={classes.meeraLink}
            >
              Meera Solution
            </Link>
          </Box>
        </WidthContainer>
      </BackgroundImage>
    </Box>
  );
};

export default memo(TheFooter);
